import React from "react";

function PromotionTableRow({ title, description, status, method, type, used }) {
    let badgeClass = 'fs-6 px-5 badge'
    switch (status) {
        case 'Active':
            badgeClass += ' badge-light-primary'
            break
        case 'Draft':
            badgeClass += ' badge-light-warning'
            break
        case 'Expired':
            badgeClass += ' badge-light-secondary'
            break
    }
    return (
        <tr>                            
            <td>
                <div className="d-flex align-items-center">
                        
                    
                    <div className="d-flex justify-content-start flex-column">
                        <a href="/" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-4">{ title }</a>
                        <span className="text-gray-500 fw-semibold d-block fs-5">{ description }</span>
                    </div>
                </div>                                
            </td>

            <td className="text-end pe-0">
                <div className="d-flex align-items-center justify-content-end">
                    <span className={badgeClass}>{status}</span>
                </div>     
            </td>
        
            <td className="text-end pe-0">
                <div className="d-flex align-items-center justify-content-end fs-5">{method}</div>
            </td>    

            <td className="text-end pe-0">
                <div className="d-flex align-items-center justify-content-end fs-5">{type}</div>
            </td>    

            <td className="text-end pe-0">
                <div className="d-flex align-items-center justify-content-end fs-5 text-gray-800">{used}</div>
            </td>    

            <td className="text-end">
                <a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
                    <span className="svg-icon svg-icon-5 svg-icon-gray-700">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z" fill="currentColor"></path>
                        </svg>
                    </span>                                    
                </a>
            </td>
        </tr>
    )
}

export default PromotionTableRow
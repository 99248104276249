import React from 'react'

function ProductTableRow({ src, name, cost, pointsCost, pointsEarn, status }) {
    return (
        <tr>
            <td>
                <div className="d-flex align-items-center">
                    <a href="/products" className="symbol symbol-50px">
                        <img className='symbol-label' src={src}></img>
                    </a>
                    <div className="ms-5">
                        <a href="/products" className="text-gray-800 text-hover-primary fs-5 fw-bold" data-kt-ecommerce-product-filter="product_name">{name}</a>
                    </div>
                </div>
            </td>
            <td className="text-end pe-0">
                <span className="fw-bold">{cost}</span>
            </td>
            <td className="text-end pe-0" data-order="37">
                <span className="fw-bold ms-3">{pointsCost}</span>
            </td>
            <td className="text-end pe-0">{pointsEarn}</td>
            <td class="text-end pe-0" data-order={status}>
                <div class="badge badge-light-success">
                    {status}
                </div>
            </td>
            <td className="text-end">
                <a href="#" className="btn btn-sm btn-light hover show hiding" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                    Actions
                    <span className="svg-icon svg-icon-5 m-0">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor"></path>
                        </svg>
                    </span>
                </a>
            </td>
        </tr>
    )
}

export default ProductTableRow
import React from 'react'
import ProductTableRow from '../components/TableRows/ProductTableRow'

function Products() {
    return (
        <div className='card card-flush mb-5'>
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                        <span className="svg-icon svg-icon-1 position-absolute ms-4">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"/>
                            <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"/>
                            </svg>
                        </span>  
                        <input type="text" data-kt-ecommerce-product-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder="Search" />
                    </div>
                </div>
                
                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                    <div className="w-100 mw-150px">
                        {/*begin::Select2*/}
                        <select className="form-select form-select-solid select2-hidden-accessible" data-control="select2" data-hide-search="true" data-placeholder="Status" data-kt-ecommerce-product-filter="status" data-select2-id="select2-data-10-mn16" tabIndex="-1" aria-hidden="true" data-kt-initialized="1">
                            <option data-select2-id="select2-data-12-el0w"></option>
                            <option value="all">All</option>
                            <option value="published">Published</option>
                            <option value="scheduled">Scheduled</option>
                            <option value="inactive">Inactive</option>
                        </select>
                        <span className="select2 select2-container select2-container--bootstrap5 w-100" dir="ltr" data-select2-id="select2-data-11-nl3f">
                            <span className="selection">
                                <span className="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex="0" aria-disabled="false" aria-labelledby="select2-jxmq-container" aria-controls="select2-jxmq-container">
                                    <span className="select2-selection__rendered" id="select2-jxmq-container" role="textbox" aria-readonly="true" title="Status">
                                        <span className="select2-selection__placeholder">Status</span>
                                    </span>
                                    <span className="select2-selection__arrow" role="presentation">
                                        <b role="presentation"></b>
                                    </span>
                                </span>
                            </span>
                            <span className="dropdown-wrapper" aria-hidden="true"></span>
                        </span>
                        {/*end::Select2*/}
                    </div>
                    <a href="/keen/demo1/../demo1/apps/ecommerce/catalog/add-product.html" className="btn btn-primary">
                        Add Product
                    </a>
                </div>
            </div>
            <div className="card-body pt-0">
                <div id="kt_ecommerce_products_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_ecommerce_products_table">
                            <thead>
                                <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                    <th className="min-w-200px sorting" tabIndex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1" aria-label="Product: activate to sort column ascending">
                                        Product
                                    </th>
                                    <th className="text-end min-w-100px sorting" tabIndex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1" aria-label="SKU: activate to sort column ascending">
                                        Cost
                                    </th>
                                    <th className="text-end min-w-100px sorting" tabIndex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1" aria-label="Qty: activate to sort column ascending">
                                        Points Cost
                                    </th>
                                    <th className="text-end min-w-100px sorting" tabIndex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1" aria-label="Price: activate to sort column ascending">
                                        Points Earned
                                    </th>
                                    <th className="text-end min-w-100px sorting" tabIndex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1" aria-label="Status: activate to sort column ascending">
                                        Status
                                    </th>
                                    <th className="text-end min-w-70px sorting_disabled" rowspan="1" colspan="1" aria-label="Actions">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                                <ProductTableRow src='/assets/media/menu/1.jpg' name='Penne Pomodoro' cost='$12.00' pointsCost='1600' pointsEarn='145' status='Published' />
                                <ProductTableRow src='/assets/media/menu/2.jpg' name='Chicken Alfredo Supreme' cost='$10.00' pointsCost='1300' pointsEarn='118' status='Published' />
                                <ProductTableRow src='/assets/media/menu/4.jpg' name='Crispy Potato Wedges' cost='$7.00' pointsCost='700' pointsEarn='64' status='Published' />
                                <ProductTableRow src='/assets/media/menu/5.jpg' name='Garden Veggie Pizza' cost='$10.00' pointsCost='1300' pointsEarn='118' status='Published' />
                                <ProductTableRow src='/assets/media/menu/6.jpg' name='Cheeseburger' cost='$11.00' pointsCost='1400' pointsEarn='127' status='Published' />
                                <ProductTableRow src='/assets/media/menu/3.jpg' name='Creamy Chicken Alfredo Penne' cost='$13.00' pointsCost='1600' pointsEarn='145' status='Published' />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Products
import React from "react";

function SecondaryNavBarItem({href, title, active}) {
    return (
        <li className="nav-item my-1">
            <a className={"btn btn-sm btn-color-gray-600 bg-state-body btn-active-color-gray-800 fw-bolder fw-bold fs-6 fs-lg-base nav-link px-3 px-lg-4 mx-1 " + (active ? 'active' : '')} href={href}> 
                {title}            
            </a>
        </li>
    )
}

export default SecondaryNavBarItem
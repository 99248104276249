import React from "react";
import DashboardPopularMenuItem from "./DashboardPopularMenuItem";

function DashboardPopularMenuItemsCard() {
    return (
        <div className="card card-flush h-xl-100">
            <div className="card-header pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark fs-3">Popular Rewards</span>
                    <span className="text-gray-400 mt-1 fw-semibold fs-6">Rewards your customers can't get enough of.</span>
                </h3>
                <div className="card-toolbar">
                    <button className="btn btn-icon btn-color-gray-400 justify-content-end hover show hiding" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                        <span className="svg-icon svg-icon-1">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor"></rect>
                                <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"></rect>
                                <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"></rect>
                                <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"></rect>
                            </svg>
                        </span>                            
                    </button>          
                </div>
            </div>
            <div className="card-body">
                <DashboardPopularMenuItem src='/assets/media/menu/1.jpg' title='Penne Pomodoro' type='Dinner' percentage='88'/>
                <div className="separator separator-dashed my-4"></div>
                <DashboardPopularMenuItem src='/assets/media/menu/3.jpg' title='Chicken Alfredo Supreme' type='Dinner' percentage='83'/>
                <div className="separator separator-dashed my-4"></div>
                <DashboardPopularMenuItem src='/assets/media/menu/4.jpg' title='Crispy Potato Wedges' type='Apetizer' percentage='79'/>
                <div className="separator separator-dashed my-4"></div>
                <DashboardPopularMenuItem src='/assets/media/menu/5.jpg' title='Garden Veggie Pizza' type='Pizza' percentage='70'/>
                <div className="separator separator-dashed my-4"></div>
            </div>
        </div>
    )
}

export default DashboardPopularMenuItemsCard
import React from "react";

function ProfileDetailsCard() {
    return (
        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
            <div className="card-header cursor-pointer">
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Profile Details</h3>
                </div>
                <a href="/profile/settings" className="btn btn-sm btn-primary align-self-center">Edit Profile</a>
            </div>
            <div className="card-body p-9">
                <div className="row mb-7">
                    <label className="col-lg-4 fw-semibold text-muted">Full Name</label>
                    <div className="col-lg-8">                    
                        <span className="fw-bold fs-6 text-gray-800">Max Smith</span>                
                    </div>
                </div>
                <div className="row mb-7">
                    <label className="col-lg-4 fw-semibold text-muted">Company</label>
                    <div className="col-lg-8 fv-row">
                        <span className="fw-semibold text-gray-800 fs-6">Keenthemes</span>                         
                    </div>
                </div>
                <div className="row mb-7">
                <label className="col-lg-4 fw-semibold text-muted">
                        Contact Phone
                        <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" aria-label="Phone number must be active" data-bs-original-title="Phone number must be active" data-kt-initialized="1"></i>                
                    </label>
                    <div className="col-lg-8 d-flex align-items-center">
                        <span className="fw-bold fs-6 text-gray-800 me-2">044 3276 454 935</span>      
                        
                        <span className="badge badge-success">Verified</span>
                    </div>
                </div>
                <div className="row mb-7">
                    <label className="col-lg-4 fw-semibold text-muted">Company Site</label>
                    <div className="col-lg-8">
                        <a href="#" className="fw-semibold fs-6 text-gray-800 text-hover-primary">keenthemes.com</a>                         
                    </div>
                </div>
                <div className="row mb-7">
                    <label className="col-lg-4 fw-semibold text-muted">
                        Country
                        <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" aria-label="Country of origination" data-bs-original-title="Country of origination" data-kt-initialized="1"></i>
                    </label>
                    <div className="col-lg-8">
                        <span className="fw-bold fs-6 text-gray-800">Germany</span> 
                    </div>
                </div>
                <div className="row mb-7">
                    <label className="col-lg-4 fw-semibold text-muted">Communication</label>
                    <div className="col-lg-8">
                        <span className="fw-bold fs-6 text-gray-800">Email, Phone</span>  
                    </div>
                </div>
                <div className="row mb-10">
                    <label className="col-lg-4 fw-semibold text-muted">Allow Changes</label>
                    <div className="col-lg-8">
                        <span className="fw-semibold fs-6 text-gray-800">Yes</span> 
                    </div>
                </div>

                
                {/*begin::Notice*/}
                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6">
                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                        <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                        <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                        </svg>
                    </span>

                    <div className="d-flex flex-stack flex-grow-1 ">
                        <div className=" fw-semibold">
                                <h4 className="text-gray-900 fw-bold">We need your attention!</h4>
                                <div className="fs-6 text-gray-700 ">Your payment was declined. To start using tools, please <a className="fw-bold" href="/keen/demo1/../demo1/account/billing.html">Add Payment Method</a>.</div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default ProfileDetailsCard
import React from "react";

function ProfileEditDetailsCard() {
    return (
        <div className="card mb-5 mb-xl-10">
            {/*begin::Card header*/}
            <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                {/*begin::Card title*/}
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Profile Details</h3>
                </div>
                {/*end::Card title*/}
            </div>
            {/*begin::Card header*/}

            {/*begin::Content*/}
            <div id="kt_account_settings_profile_details" className="collapse show">
                {/*begin::Form*/}
                <form id="kt_account_profile_details_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                    {/*begin::Card body*/}
                    <div className="card-body border-top p-9">
                        {/*begin::Input group*/}
                        <div className="row mb-6">
                            {/*begin::Label*/}
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">Avatar</label>   
                            {/*end::Label*/}  
                            
                            {/*begin::Col*/}
                            <div className="col-lg-8">
                                {/*begin::Image input*/}
                                <div className="image-input image-input-outline" data-kt-image-input="true">
                                    {/*begin::Preview existing avatar*/}
                                    <img className="image-input-wrapper w-125px h-125px" src='/assets/media/restaurant/1.jpg' />
                                    {/*end::Preview existing avatar*/}

                                    {/*begin::Label*/}
                                    <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" aria-label="Change avatar" data-bs-original-title="Change avatar" data-kt-initialized="1">
                                        <i className="bi bi-pencil-fill fs-7"></i>

                                        {/*begin::Inputs*/}
                                        <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                        <input type="hidden" name="avatar_remove" />
                                        {/*end::Inputs*/}
                                    </label>
                                    {/*end::Label*/}

                                    {/*begin::Cancel*/}
                                    <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" aria-label="Cancel avatar" data-bs-original-title="Cancel avatar" data-kt-initialized="1">
                                        <i className="bi bi-x fs-2"></i>
                                    </span>
                                    {/*end::Cancel*/}

                                    {/*begin::Remove*/}
                                    <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" aria-label="Remove avatar" data-bs-original-title="Remove avatar" data-kt-initialized="1">
                                        <i className="bi bi-x fs-2"></i>
                                    </span>
                                    {/*end::Remove*/}
                                </div>
                                {/*end::Image input*/}

                                {/*begin::Hint*/}
                                <div className="form-text">Allowed file types:  png, jpg, jpeg.</div>
                                {/*end::Hint*/}
                            </div>
                            {/*end::Col*/}
                        </div>
                        {/*end::Input group*/}

                        {/*begin::Input group*/}
                        <div className="row mb-6">
                            {/*begin::Label*/}
                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Full Name</label>
                            {/*end::Label*/}

                            {/*begin::Col*/}
                            <div className="col-lg-8">
                                {/*begin::Row*/}
                                <div className="row">
                                    {/*begin::Col*/}
                                    <div className="col-lg-6 fv-row fv-plugins-icon-container">
                                        <input type="text" name="fname" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="First name" value="Max" />
                                    <div className="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/*end::Col*/}

                                    {/*begin::Col*/}
                                    <div className="col-lg-6 fv-row fv-plugins-icon-container">
                                        <input type="text" name="lname" className="form-control form-control-lg form-control-solid" placeholder="Last name" value="Smith" />
                                    <div className="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/*end::Col*/}
                                </div>
                                {/*end::Row*/}
                            </div>
                            {/*end::Col*/}
                        </div>
                        {/*end::Input group*/}

                        {/*begin::Input group*/}
                        <div className="row mb-6">
                            {/*begin::Label*/}
                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Company</label>
                            {/*end::Label*/}

                            {/*begin::Col*/}
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                    <input type="text" name="company" className="form-control form-control-lg form-control-solid" placeholder="Company name" value="Keenthemes" />
                                <div className="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                            {/*end::Col*/}
                        </div>
                        {/*end::Input group*/}

                        {/*begin::Input group*/}
                        <div className="row mb-6">
                            {/*begin::Label*/}
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                <span className="required">Contact Phone</span>

                                <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" aria-label="Phone number must be active" data-bs-original-title="Phone number must be active" data-kt-initialized="1"></i>
                            </label>
                            {/*end::Label*/}
                            
                            {/*begin::Col*/}
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                <input type="tel" name="phone" className="form-control form-control-lg form-control-solid" placeholder="Phone number" value="555-555-5555"/>
                                <div className="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                            {/*end::Col*/}
                        </div>
                        {/*end::Input group*/}

                        {/*begin::Input group*/}
                        <div className="row mb-6">
                            {/*begin::Label*/}
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">Company Site</label>
                            {/*end::Label*/}

                            {/*begin::Col*/}
                            <div className="col-lg-8 fv-row">
                                <input type="text" name="website" className="form-control form-control-lg form-control-solid" placeholder="Company website" value="keenthemes.com" />
                            </div>
                            {/*end::Col*/}
                        </div>
                        {/*end::Input group*/}

                        {/*begin::Input group*/}
                        <div className="row mb-6">
                            {/*begin::Label*/}
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                <span className="required">Country</span>

                                <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" aria-label="Country of origination" data-bs-original-title="Country of origination" data-kt-initialized="1"></i>
                            </label>
                            {/*end::Label*/}

                            {/*begin::Col*/}
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                <select name="country" aria-label="Select a Country" data-control="select2" data-placeholder="Select a country..." className="form-select form-select-solid form-select-lg fw-semibold select2-hidden-accessible" data-select2-id="select2-data-10-c8ju" tabIndex="-1" aria-hidden="true" data-kt-initialized="1">
                                    <option value="" data-select2-id="select2-data-12-wa6q">Select a Country...</option>
                                </select><span className="select2 select2-container select2-container--bootstrap5 w-100" dir="ltr" data-select2-id="select2-data-11-rf36"><span className="selection"><span className="select2-selection select2-selection--single form-select form-select-solid form-select-lg fw-semibold" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex="0" aria-disabled="false" aria-labelledby="select2-country-vh-container" aria-controls="select2-country-vh-container"><span className="select2-selection__rendered" id="select2-country-vh-container" role="textbox" aria-readonly="true" title="Select a country..."><span className="select2-selection__placeholder">Select a country...</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span>
                            <div className="fv-plugins-message-container invalid-feedback"></div></div>
                            {/*end::Col*/}
                        </div>
                        {/*end::Input group*/}

                        {/*begin::Input group*/}
                        <div className="row mb-6">
                            {/*begin::Label*/}
                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Language</label>
                            {/*end::Label*/}

                            {/*begin::Col*/}
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                {/*begin::Input*/}
                                <select name="language" aria-label="Select a Language" data-control="select2" data-placeholder="Select a language..." className="form-select form-select-solid form-select-lg select2-hidden-accessible" data-select2-id="select2-data-13-xlgg" tabIndex="-1" aria-hidden="true" data-kt-initialized="1">
                                    <option value="" data-select2-id="select2-data-15-yb0p">Select a Language...</option>
                                                                    
                                </select>
                                <span className="select2 select2-container select2-container--bootstrap5 w-100" dir="ltr" data-select2-id="select2-data-14-hfyr">
                                <span className="selection">
                                <span className="select2-selection select2-selection--single form-select form-select-solid form-select-lg" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex="0" aria-disabled="false" aria-labelledby="select2-language-s3-container" aria-controls="select2-language-s3-container"><span className="select2-selection__rendered" id="select2-language-s3-container" role="textbox" aria-readonly="true" title="Select a language..."><span className="select2-selection__placeholder">Select a language...</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span>
                                {/*end::Input*/}

                                {/*begin::Hint*/}
                                <div className="form-text">
                                    Please select a preferred language, including date, time, and number formatting.
                                </div>
                                {/*end::Hint*/}
                            <div className="fv-plugins-message-container invalid-feedback"></div></div>
                            {/*end::Col*/}
                        </div>
                        {/*end::Input group*/}

                        {/*begin::Input group*/}
                        <div className="row mb-6">
                            {/*begin::Label*/}
                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Time Zone</label>
                            {/*end::Label*/}

                            {/*begin::Col*/}
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                <select name="timezone" aria-label="Select a Timezone" data-control="select2" data-placeholder="Select a timezone.." className="form-select form-select-solid form-select-lg select2-hidden-accessible" data-select2-id="select2-data-16-4awd" tabIndex="-1" aria-hidden="true" data-kt-initialized="1">
                                    <option value="" data-select2-id="select2-data-18-zmtg">Select a Timezone..</option>
                                                                    
                                </select>
                                <span className="select2 select2-container select2-container--bootstrap5 w-100" dir="ltr" data-select2-id="select2-data-17-e9td">
                                    <span className="selection">
                                        <span className="select2-selection select2-selection--single form-select form-select-solid form-select-lg" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex="0" aria-disabled="false" aria-labelledby="select2-timezone-k3-container" aria-controls="select2-timezone-k3-container">
                                            <span className="select2-selection__rendered" id="select2-timezone-k3-container" role="textbox" aria-readonly="true" title="Select a timezone..">
                                                <span className="select2-selection__placeholder">Select a timezone..</span>
                                            </span>
                                            <span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>
                                        </span>
                                    </span>
                                    <span className="dropdown-wrapper" aria-hidden="true"></span>
                                </span>
                                <div className="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                            {/*end::Col*/}
                        </div>
                        {/*end::Input group*/}

                        {/*begin::Input group*/}
                        <div className="row mb-6">
                            {/*begin::Label*/}
                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">Currency</label>
                            {/*end::Label*/}

                            {/*begin::Col*/}
                            <div className="col-lg-8 fv-row">
                                <select name="currnecy" aria-label="Select a Currency" data-control="select2" data-placeholder="Select a currency.." className="form-select form-select-solid form-select-lg select2-hidden-accessible" data-select2-id="select2-data-19-7rci" tabIndex="-1" aria-hidden="true" data-kt-initialized="1">
                                    <option value="" data-select2-id="select2-data-21-payn">Select a currency..</option>
                                                                    <option data-kt-flag="flags/united-states.svg" value="USD">USD&nbsp;-&nbsp;USA dollar</option>
                                                                    <option data-kt-flag="flags/united-kingdom.svg" value="GBP">GBP&nbsp;-&nbsp;British pound</option>
                                                                    <option data-kt-flag="flags/australia.svg" value="AUD">AUD&nbsp;-&nbsp;Australian dollar</option>
                                                                    <option data-kt-flag="flags/japan.svg" value="JPY">JPY&nbsp;-&nbsp;Japanese yen</option>
                                                                    <option data-kt-flag="flags/sweden.svg" value="SEK">SEK&nbsp;-&nbsp;Swedish krona</option>
                                                                    <option data-kt-flag="flags/canada.svg" value="CAD">CAD&nbsp;-&nbsp;Canadian dollar</option>
                                                                    <option data-kt-flag="flags/switzerland.svg" value="CHF">CHF&nbsp;-&nbsp;Swiss franc</option>
                                                            </select><span className="select2 select2-container select2-container--bootstrap5 w-100" dir="ltr" data-select2-id="select2-data-20-dssk"><span className="selection"><span className="select2-selection select2-selection--single form-select form-select-solid form-select-lg" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex="0" aria-disabled="false" aria-labelledby="select2-currnecy-9j-container" aria-controls="select2-currnecy-9j-container"><span className="select2-selection__rendered" id="select2-currnecy-9j-container" role="textbox" aria-readonly="true" title="Select a currency.."><span className="select2-selection__placeholder">Select a currency..</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span>
                            </div>
                            {/*end::Col*/}
                        </div>
                        {/*end::Input group*/}

                        {/*begin::Input group*/}
                        <div className="row mb-6">
                            {/*begin::Label*/}
                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Communication</label>
                            {/*end::Label*/}

                            {/*begin::Col*/}
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                {/*begin::Options*/}
                                <div className="d-flex align-items-center mt-3">
                                    {/*begin::Option*/}
                                    <label className="form-check form-check-custom form-check-inline form-check-solid me-5">
                                        <input className="form-check-input" name="communication[]" type="checkbox" value="1" />
                                        <span className="fw-semibold ps-2 fs-6">
                                            Email
                                        </span>
                                    </label>
                                    {/*end::Option*/}

                                    {/*begin::Option*/}
                                    <label className="form-check form-check-custom form-check-inline form-check-solid">
                                        <input className="form-check-input" name="communication[]" type="checkbox" value="2" />
                                        <span className="fw-semibold ps-2 fs-6">
                                            Phone
                                        </span>
                                    </label>
                                    {/*end::Option*/}
                                </div>
                                {/*end::Options*/}
                            <div className="fv-plugins-message-container invalid-feedback"></div></div>
                            {/*end::Col*/}
                        </div>
                        {/*end::Input group*/}

                        {/*begin::Input group*/}
                        <div className="row mb-0">
                            {/*begin::Label*/}
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">Allow Marketing</label>
                            {/*begin::Label*/}

                            {/*begin::Label*/}
                            <div className="col-lg-8 d-flex align-items-center">
                                <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                    <input className="form-check-input w-45px h-30px" type="checkbox" id="allowmarketing" checked="" />
                                    <label className="form-check-label" htmlFor="allowmarketing"></label>
                                </div>
                            </div>
                            {/*begin::Label*/}
                        </div>
                        {/*end::Input group*/}
                    </div>
                    {/*end::Card body*/}

                    {/*begin::Actions*/}
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button type="reset" className="btn btn-light btn-active-light-primary me-2">Discard</button>

                <button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit">Save Changes</button>
            </div>
            {/*end::Actions*/}
        <input type="hidden" />

        </form>
        {/*end::Form*/}
    </div>
    {/*end::Content*/}
</div>
    )
}

export default ProfileEditDetailsCard
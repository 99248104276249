import React from "react";

function OrderCard() {
    return (
        <div className="card card-flush h-md-100">
            <div className="card-body py-9">         
                <div className="gx-9 h-100">             
                    <div>            
                        <div className="d-flex flex-column h-100">           
                            <div className="mb-7">        
                                <div className="d-flex flex-stack mb-6">          
                                    <div className="flex-shrink-0 me-5">
                                        <span className="text-gray-400 fs-7 fw-bold me-2 d-block lh-1 pb-1">Order number</span>

                                        <span className="text-gray-800 fs-1 fw-bold">3237</span>
                                    </div>

                                    <span className="badge badge-light-primary flex-shrink-0 align-self-center py-3 px-4 fs-7">In Progess</span>
                                </div>             
                                <div className="d-flex align-items-center flex-wrap d-grid gap-2">
                                    <div className="d-flex align-items-center me-5 me-xl-13">
                                        <div className="m-0">                            
                                            <span className="fw-semibold text-gray-400 d-block fs-6">Name</span>
                                            <a href="/keen/demo1/../demo1/pages/user-profile/overview.html" className="fw-bold text-gray-800 text-hover-primary fs-5">Robert Fox</a>
                                        </div>
                                    </div>                   
                                    <div className="d-flex align-items-center ms-7 d-none">                    
                                        <div className="m-0">                            
                                            <span className="fw-semibold text-gray-400 d-block fs-6">Total</span>
                                            <span className="fw-bold text-gray-800 fs-5">$64.80</span>
                                        </div>
                                    </div>      
                                </div>
                            </div>             
                            <div className="mb-6">
                                <div className="d-flex">
                                    <div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6 mb-3">                             
                                        <span className="fs-6 text-gray-700 fw-bold">Feb 6, 2023 </span>
                                        <div className="fw-semibold text-gray-400">Date</div>
                                    </div>
                                    <div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 mb-3">             
                                        <span className="fs-6 text-gray-700 fw-bold">$28.49</span>
                                        <div className="fw-semibold text-gray-400">Total</div>
                                    </div>                           
                                </div>
                            </div>

                            <div className="d-flex flex-stack mt-auto bd-highlight justify-content-end">
                   
                                <a href="/keen/demo1/../demo1/apps/projects/project.html" className="text-primary opacity-75-hover fs-6 fw-semibold">
                                    View Order
                                    <span className="svg-icon svg-icon-4 svg-icon-gray-800 ms-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor"></path>
                                    <rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor"></rect>
                                    <path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor"></path>
                                    </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>       
            </div>
        </div>
    )
}

export default OrderCard
import React from "react";
import ProfileHeaderCard from "../components/Card/Profile/ProfileHeaderCard";
import ProfileDetailsCard from "../components/Card/Profile/ProfileDetailsCard";

function Profile() {
    return (
        <div>
            <ProfileHeaderCard />
            <ProfileDetailsCard />
        </div>
    )
}

export default Profile
import React from "react";

function ProfileHeaderNavBarItem({href, title, active}) {
    return (
        <li className="nav-item">
            <a className={"nav-link text-active-primary py-5 me-6 " + (active ? 'active' : '')} href={href}>
                {title}
            </a>
        </li>
    )
}

export default ProfileHeaderNavBarItem
import React from 'react'
import NavBar from '../components/NavBar/NavBar'
import Sidebar from '../components/Sidebar/Sidebar'
import Toolbar from '../components/Toolbar/Toolbar'

function Main({page, component}) {
    return (
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
            <NavBar />
            <div className="app-wrapper  flex-column flex-row-fluid " id="kt_app_wrapper">
                <Sidebar selectedItem={page} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                    <div className="d-flex flex-column flex-column-fluid">
                        <Toolbar title={page}/>
                        
                        <div id="kt_app_content" className="app-content  flex-column-fluid ">
                            <div id="kt_app_content_container" className="app-container  container-fluid ">
                               
                                {/* HANDLE DISPLAY */}
                                {component}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main
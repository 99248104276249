import React from "react";
import SecondaryNavBarItem from "./SecondaryNavBarItem";

function OrdersNavBar({path
}) {
    const isActivePath = window.location.pathname === '/orders'
    const isHistoryPath = window.location.pathname === '/orders/history'
    return (
        <div id="kt_user_profile_nav" className="rounded bg-gray-200 d-flex flex-stack flex-wrap mb-9 p-2" data-kt-page-scroll-position="400" data-kt-sticky="true" data-kt-sticky-name="sticky-profile-navs" data-kt-sticky-offset="{default: false, lg: '200px'}" data-kt-sticky-width="{target: '#kt_user_profile_panel'}" data-kt-sticky-left="auto" data-kt-sticky-top="70px" data-kt-sticky-animation="false" data-kt-sticky-zindex="95">
            <ul className="nav flex-wrap border-transparent">
                <SecondaryNavBarItem title='Active Orders' href='/orders' active={isActivePath} />
                <SecondaryNavBarItem title='Order History' href='/orders/history' active={isHistoryPath} />
                {/* <SecondaryNavBarItem title='' href='/orders' /> */}
            </ul>
        </div>
    )
}

export default OrdersNavBar
import React from "react";
import GettingStartedCard from '../components/Card/GettingStartedCard';
import PromotionTableRow from "../components/TableRows/PromotionTableRow";

let promotions = []
function getPromotions() {
    promotions = []
    let result = [
        {
            "title": 'New Customer Discount',
            "description": 'x2 Points for new customers',
            "status": 'Active',
            "method": 'Automatic',
            "type": 'Product discount',
            "used": 84732
        },
        {
            "title": 'Free desert',
            "description": 'Free desert with purchase of combo',
            "status": 'Draft',
            "method": 'Scan',
            "type": 'Free Product',
            "used": 2749
        },
        {
            "title": 'BOGO Wings',
            "description": 'x2 Points for new customers',
            "status": 'Active',
            "method": 'Scan',
            "type": 'Product discount',
            "used": 4389
        },
        {
            "title": 'New Customer Discount',
            "description": 'x2 Points for new customers',
            "status": 'Expired',
            "method": 'Automatic',
            "type": 'Product discount',
            "used": 193893
        },
    ]
    result.forEach(promotion =>
        promotions.push(
            <PromotionTableRow 
            title={promotion.title}
            description={promotion.description}
            status={promotion.status}
            method={promotion.method}
            type={promotion.type}
            used={promotion.used.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            />
        )
    );
}

function Promotions() {
    getPromotions();
    let component
    console.log(promotions.length)
    if (promotions.length === 0) {
        component = 
        <GettingStartedCard 
        title='Create a promotion' 
        description='Add your first promotion' 
        buttonTitle='Add Promotion' 
        href='/' 
        src='assets/media/illustrations/unitedpalms-1/7.png'
        />
    } else {
        component = 
        <div className='card card-flush mb-5'>
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                        <span className="svg-icon svg-icon-1 position-absolute ms-4">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"/>
                            <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"/>
                            </svg>
                        </span>  
                        <input type="text" data-kt-ecommerce-product-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder="Search" />
                    </div>
                </div>
                
                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                    <a href="/keen/demo1/../demo1/apps/ecommerce/catalog/add-product.html" className="btn btn-primary">
                        Add Promotion
                    </a>
                </div>
            </div>
            <div className="card-body pt-0">
                <div id="kt_ecommerce_products_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_ecommerce_products_table">
                            <thead>
                                <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                    <th className="min-w-200px sorting" tabIndex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1" aria-label="Title: activate to sort column ascending">
                                        Title
                                    </th>
                                    <th className="text-end min-w-100px sorting" tabIndex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1" aria-label="Status: activate to sort column ascending">
                                        Status
                                    </th>
                                    <th className="text-end min-w-100px sorting" tabIndex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1" aria-label="Method: activate to sort column ascending">
                                        Method
                                    </th>
                                    <th className="text-end min-w-100px sorting" tabIndex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1" aria-label="Type: activate to sort column ascending">
                                        Type
                                    </th>
                                    <th className="text-end min-w-100px sorting" tabIndex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1" aria-label="Used: activate to sort column ascending">
                                        Used
                                    </th>
                                    <th className="text-end min-w-70px sorting_disabled" rowspan="1" colspan="1" aria-label="View">
                                        View
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                                {/* PROMOTIONS LIST */}
                                {promotions}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    }
    return (
        <div>
            {component}
        </div>
    )
}

export default Promotions
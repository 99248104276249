import React from 'react'



function DashboardBarGraphCard({title, symbol, value, percentage}) {
    return (
        <div className="card card-flush h-md-100 mb-5 mb-xl-10">
            <div className="card-header pt-5">
                <div className="card-title d-flex flex-column"> 
                    <div className="d-flex align-items-center">
                        <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">{symbol}</span>
                        <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{value}</span>

                        {
                            (() => {
                                if(percentage === undefined) { return }
                                if(percentage > 0) {
                                        return (
                                            <span className="badge badge-light-success fs-base">
                                                <span className="svg-icon svg-icon-5 svg-icon-success ms-n1"><i className="las la-arrow-up" style={{color: 'currentcolor'}}></i></span>
                                                {percentage + '%'}
                                            </span>
                                        )
                                    } else {
                                        return (
                                            <span className="badge badge-light-danger fs-base">
                                                <span className="svg-icon svg-icon-5 svg-icon-danger ms-n1"><i className="las la-arrow-down" style={{color: 'currentcolor'}}></i></span>
                                                {-percentage + '%'}
                                            </span>
                                        )
                                    }
                            })()  
                        } 
                        {/* <span className="badge badge-light-success fs-base">
                            <span className="svg-icon svg-icon-5 svg-icon-success ms-n1"><i className="las la-arrow-up" style={{color: 'currentcolor'}}></i></span>
                            2.6%
                        </span> */}
                    </div>
                    <span className="text-gray-400 pt-1 fw-semibold fs-6">{title}</span>
                </div>
            </div>
            <div className="card-body d-flex align-items-end px-0 pb-0">
                <div id="kt_card_widget_6_chart" className="w-100 h-80px">
                    <div id="kt_apexcharts_3"></div>
                </div>
            </div>
        </div>
    )
  }

export default DashboardBarGraphCard
import React from "react";
import ProfileEditDetailsCard from "../components/Card/Profile/ProfileEditDetailsCard";
import ProfileHeaderCard from "../components/Card/Profile/ProfileHeaderCard";

function ProfileSettings() {
    return (
        <div>
            <ProfileHeaderCard />
            <ProfileEditDetailsCard />
        </div>
    )
}

export default ProfileSettings
import React from "react";

function RoleNewCard() {
    return (
        <div className="card h-md-100">
            {/*begin::Card body*/}
            <div className="card-body d-flex flex-center">
                {/*begin::Button*/}
                <button type="button" className="btn btn-clear d-flex flex-column flex-center" data-bs-toggle="modal" data-bs-target="#kt_modal_add_role">
                    {/*begin::Illustration*/}
                    <img src="/assets/media/illustrations/unitedpalms-1/4.png" alt="" className="mw-100 mh-150px mb-7" />                      
                    {/*end::Illustration*/}

                    {/*begin::Label*/}
                    <div className="fw-bold fs-3 text-gray-600 text-hover-primary">Add New Role</div>
                    {/*end::Label*/}
                </button>
                {/*begin::Button*/}
            </div>
            {/*begin::Card body*/}
        </div>
    )
}

export default RoleNewCard
import React from "react";
import ProfileHeaderNavBarItem from "./ProfileHeaderNavBarItem";

function ProfielHeaderNavBar() {
    const path = window.location.pathname
    const isOverviewPath = path === '/profile'
    const isRolesPath = path === '/profile/roles'
    const isUserPath = path === '/profile/users'
    const isSettingsPath = path === '/profile/settings'
    return (
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
            <ProfileHeaderNavBarItem href='/profile' title='Overview' active={isOverviewPath}/>
            <ProfileHeaderNavBarItem href='/profile/roles' title='Roles' active={isRolesPath}/>
            <ProfileHeaderNavBarItem href='/profile/users' title='Users' active={isUserPath}/>
            <ProfileHeaderNavBarItem href='/profile/settings' title='Settings' active={isSettingsPath}/>

        </ul>
    )
}

export default ProfielHeaderNavBar
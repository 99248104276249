import React from "react";
import OrderCard from "../components/Card/OrderCard";
import OrdersNavBar from "../components/NavBar/OrdersNavBar";

function Orders() {
    return (
        <div>
            <OrdersNavBar />
            <div className="row">
                <div className="col-md-6 col-lg-6 col-xl-4 col-xxl-3 mb-5">
                    <OrderCard />
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4 col-xxl-3 mb-5">
                    <OrderCard />
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4 col-xxl-3 mb-5">
                    <OrderCard />
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4 col-xxl-3 mb-5">
                    <OrderCard />
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4 col-xxl-3 mb-5">
                    <OrderCard />
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4 col-xxl-3 mb-5">
                    <OrderCard />
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4 col-xxl-3 mb-5">
                    <OrderCard />
                </div>
            </div>
        </div>
    )
}

export default Orders
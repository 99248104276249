import React from "react";

function PerformanceLineGraphCard() {
    return (
        <div className="card card-flush overflow-hidden h-md-100">
            {/*begin::Header*/}
            <div className="card-header py-5">
                {/*begin::Title*/}
                <h3 className="card-title align-items-start flex-column">            
                    <span className="card-label fw-bold text-dark">Sales This Months</span>
                    <span className="text-gray-400 mt-1 fw-semibold fs-6">Users from all channels</span>
                </h3>
                {/*end::Title*/}

                {/*begin::Toolbar*/}
                <div className="card-toolbar">    
                    {/*begin::Menu*/}
                    <button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">   
                                        
                        {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
            <span className="svg-icon svg-icon-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor"></rect>
            <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"></rect>
            <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"></rect>
            <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"></rect>
            </svg>
            </span>
            {/*end::Svg Icon*/}                             
                    </button>
                    
                    
            {/*begin::Menu 2*/}

            {/*end::Menu 2*/}

                    {/*end::Menu*/}                        
                </div>
                {/*end::Toolbar*/}
            </div>
            {/*end::Header*/}

            {/*begin::Card body*/}
            <div className="card-body d-flex justify-content-between flex-column pb-1 px-0">  
                {/*begin::Statistics*/}
                <div className="px-9 mb-5">   
                    {/*begin::Statistics*/}
                    <div className="d-flex mb-2">   
                        <span className="fs-4 fw-semibold text-gray-400 me-1">$</span>
                        <span className="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">74,094</span>                
                    </div>
                    {/*end::Statistics*/}

                    {/*begin::Description*/}
                    <span className="fs-6 fw-semibold text-gray-400">Another $48,346 to Goal</span>
                    {/*end::Description*/}
                </div>
                {/*end::Statistics*/}

                {/*begin::Chart*/}
                <div id="kt_charts_widget_3" className="min-h-auto ps-4 pe-6" style={{height: 315}}>
                    <div id="apexchartsveic4ym9" className="apexcharts-canvas apexchartsveic4ym9 apexcharts-theme-light" style={{height: 300}}>
                        <img src='/assets/media/misc/line-graph-1.png'></img>
                    </div>
                </div>
                {/*end::Chart*/} 
            </div>
            {/*end::Card body*/}
        </div>
    )
}

export default PerformanceLineGraphCard
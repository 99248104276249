import React from "react";

function DashboardPopularMenuItem({ src, title, type, percentage }) {
    return (
        <div className="d-flex flex-stack">                                     
            <div className="d-flex align-items-center me-3">          
                <img src={src} className="me-3 w-60px" alt="" />
                <div className="flex-grow-1">
                    <a href="#" className="text-gray-800 text-hover-primary fs-5 fw-bold lh-0">{title}</a>
                    <span className="text-gray-400 fw-semibold d-block fs-6">{type}</span>
                </div>
            </div>
            <div className="d-flex align-items-center w-100 mw-125px">  
                <div className="progress h-6px w-100 me-2 bg-light-success">
                    <div className="progress-bar bg-success" role="progressbar" style={{width: percentage + "%"}} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <span className="text-gray-400 fw-semibold">
                    {percentage}%
                </span>
            </div>
        </div>
    )
}

export default DashboardPopularMenuItem
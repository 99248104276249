import React from 'react'

function GettingStartedCard({title, description, buttonTitle, buttonAttributes, href, src}) {
  let buttonClass = "btn btn-primary " + buttonAttributes
  return (
    <div className="card ">
        <div className="card-body p-0">
            <div className="card-px text-center py-20 my-10">
                <h2 className="fs-2x fw-bold mb-10">{title}</h2>
                <p className="text-gray-400 fs-4 fw-semibold mb-10 w-400px mx-auto">
                    {description}
                </p>
                <a href={href} className={buttonClass}>{buttonTitle}</a>
            </div>
            <div className="text-center px-4">
                <img className="mw-100 mh-300px" alt="" src={src} />
            </div>
        </div>
    </div>
  )
}

export default GettingStartedCard
import React, { PureComponent } from 'react'
import GettingStartedCard from '../components/Card/GettingStartedCard'

export class Customers extends PureComponent {
  render() {
    return (
      <GettingStartedCard 
      title='No Customers' 
      description='There are currently no customers customers will be added when an order is created.' 
      buttonTitle='Go Home'
      href="/"
      src='assets/media/illustrations/unitedpalms-1/20.png'
      />

    )
  }
}

export default Customers
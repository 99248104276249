import React from "react";

function LocationTableRow({ name, address, orders, percentage }) {
    return (
        <tr>                            
            <td>
                <div className="d-flex align-items-center">
                        
                    
                    <div className="d-flex justify-content-start flex-column">
                        <a href="/keen/demo1/../demo1/pages/user-profile/overview.html" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-4">{ name }</a>
                        <span className="text-gray-400 fw-semibold d-block fs-6">{ address }</span>
                    </div>
                </div>                                
            </td>

            <td className="text-end pe-0">
                <div className="d-flex justify-content-end"> 
                    {/*begin::Number*/}           
                    <span className="text-gray-800 fw-bold fs-6 me-3 d-block">{ orders }</span> 
                    {/*end::Number*/}                        
                    
                    {/*begin::Label*/} 
                    <div className="m-0">
                            {/*begin::Label*/} 
                            <span className="badge badge-light-success fs-base">                                
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                <span className="svg-icon svg-icon-5 svg-icon-success ms-n1">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor"></rect>
                                        <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}                                 
                                { percentage }
                            </span>  
                            {/*end::Label*/}   
                                                
                    </div>  
                    {/*end::Label*/}                  
                </div>                              
            </td>
        
            <td className="text-end pe-0">
                <p>Chart</p>
            </td>    

            <td className="text-end">
                <a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
                    <span className="svg-icon svg-icon-5 svg-icon-gray-700">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z" fill="currentColor"></path>
                        </svg>
                    </span>                                    
                </a>
            </td>
        </tr>
    )
}

export default LocationTableRow
import React from 'react'

function SidebarMenuItem({ svg, title, active = false, href }) {
    return (
        <div className='menu-item' id='kt_app_sidebar_logo'>
            <a className={`menu-link ${active ? 'active' : ''}`} href={href}>
                <span className='menu-icon'>
                    <span className="svg-icon svg-icon-2" dangerouslySetInnerHTML={{ __html: svg }} />
                </span>
                <span className='menu-title'>{title}</span>
            </a>
        </div>
    )
}

export default SidebarMenuItem
import React from "react";
import ProfileHeaderCard from "../components/Card/Profile/ProfileHeaderCard";
import RoleCard from "../components/Card/RoleCard";
import RoleNewCard from "../components/Card/RoleNewCard";

function ProfileRoles({ roles }) {

    const adminPermissions = [
        'View and edit business information',
        'View and edit locations',
        'View and edit managers',
        'View all location analytics',
        'View finacial summaries'
    ]

    const basePermissions = [
        'View business information',
        'View locations',
        'View and edit managers',
        'View all location analytics',
        'View finacial summaries'
    ]

    return (
        <div>
            <ProfileHeaderCard />
            <div className="row">
                <div className="col-md-4 mb-8">
                    <RoleCard title='Admin' rules={adminPermissions} />
                </div>
                <div className="col-md-4 mb-8">
                    <RoleCard title='Finance' rules={basePermissions} />
                </div>
                <div className="col-md-4 mb-8">
                    <RoleCard title='Manager' rules={basePermissions} />
                </div>
                <div className="col-md-4 mb-8">
                    <RoleCard title='Support' rules={basePermissions} />
                </div>
                <div className="col-md-4 mb-8">
                    <RoleNewCard />
                </div>
            </div>
        </div>
    )
}

export default ProfileRoles
import React from 'react'
import DashboardBarGraphCard from '../components/Card/Dashboard/DashboardBarGraphCard'
import DashboardCustomerCard from '../components/Card/Dashboard/DashboardCustomerCard'
import DashboardPopularMenuItemsCard from '../components/Card/Dashboard/DashboardPopularMenuItemsCard'
import PerformanceLineGraphCard from '../components/Card/PerformanceLineGraphCard'

function Home() {
    return (
        <div className='row'>
            <div class="col-lg-12 col-xl-12 col-xxl-7 mb-5 mb-xl-10">
                <PerformanceLineGraphCard />
            </div>
            <div class="col-lg-12 col-xl-12 col-xxl-5 mb-5 mb-xl-10">
                <DashboardPopularMenuItemsCard />
            </div>
            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                <DashboardBarGraphCard title='Daily sales' symbol="$" value='11,340' percentage='-4.56' />
            </div>
            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                <DashboardBarGraphCard title='Daily orders' value='378' percentage='1.23'/>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                <DashboardBarGraphCard title='Redeemed rewards' value='3.2k' percentage='13.43'/>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                <DashboardCustomerCard />
            </div>
        </div>
    )
}

export default Home
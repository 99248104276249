import React from 'react'

function NavBar() {
    return (
        <div id="kt_app_header" className="app-header">
            <nav className="navbar navbar-expand-md navbar-light flex-fill bg-white" aria-label="Navbar">
                <div className="container-fluid">
                    <span className='flex-fill' />
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#dashboardNavbar" aria-controls="dashboardNavbar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-end" id="dashboardNavbar">
                        <ul className="navbar-nav me-8 mb-2 mb-md-0">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false"><span className='menu-title h5'>Wok - Asian Food</span></a>
                                <ul className="dropdown-menu dropdown-menu-end menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px dropdown-menu py-3">
                                    <li>
                                        <div className="menu-item px-3">
                                            <a href="/profile" className="menu-link px-3">
                                                Account
                                            </a>
                                        </div>
                                    </li>
                                    <li><div className="separator my-2"></div></li>
                                    <li>
                                        <div className="menu-item px-3">
                                            <a href="#" className="menu-link px-3">
                                                Sign Out
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default NavBar
import logo from './logo.svg';
import './App.css';
import Main from './components/Main'
import Customers from './pages/Customers'
import Home from './pages/Home';
import Locations from './pages/Locations';
import Products from './pages/Products';
import Orders from './pages/Orders';
import OrdersHistory from './pages/OrdersHistory';
import Promotions from './pages/Promotions';
import Profile from './pages/Profile';
import ProfileSettings from './pages/ProfileSettings';
import ProfileRoles from './pages/ProfileRoles';
import ProfileUsers from './pages/ProfileUsers';

function App() {
  let component
  let page
  switch (window.location.pathname) {
    case '/':
      component = <Home />
      page = 'Home'
      break
    case '/locations':
      component = <Locations />
      page = 'Locations'
      break
    case '/products':
      component = <Products />
      page = 'Products'
      break
    case '/orders':
      component = <Orders />
      page = 'Orders'
      break
    case '/orders/history':
      component = <OrdersHistory />
      page = 'Orders'
      break
    case '/promotions':
      component = <Promotions />
      page = 'Promotions'
      break
    case '/customers':
      component = <Customers />
      page = 'Customers'
      break
    case '/chat':
      // component = <Chat />
      page = 'Chat'
      break
    case '/profile':
      component = <Profile />
      page = 'Profile'
      break
    case '/profile/roles':
      component = <ProfileRoles />
      page = 'Profile'
      break
    case '/profile/users':
      component = <ProfileUsers />
      page = 'Profile'
      break
    case '/profile/settings':
      component = <ProfileSettings />
      page = 'Profile'
      break
  }
  return (
    <Main page={page} component={component} />
  );
}

export default App;

import React from "react";
import ProfileHeaderCard from "../components/Card/Profile/ProfileHeaderCard";
import ProfileUserTableRow from "../components/TableRows/ProfileUserTableRow";

function ProfileUsers() {
    return (
        <div>
            <ProfileHeaderCard />
            <div className="card">
                <div className="card-header border-0 pt-6">
                    {/*begin::Card title*/}
                    <div className="card-title">
                        {/*begin::Search*/}
                        <div className="d-flex align-items-center position-relative my-1">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
                            <span className="svg-icon svg-icon-1 position-absolute ms-6">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                </svg>
                            </span>
                            {/*end::Svg Icon*/}                
                            <input type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder="Search user"/>
                        </div>
                    </div>
                </div>
        
                <div className="card-body py-4">
        
                {/*begin::Table*/}
                <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="table-responsive"><table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
                    {/*begin::Table head*/}
                    <thead>
                        {/*begin::Table row*/}
                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                            <th className="min-w-125px w-400px sorting" tabIndex="0" aria-controls="kt_table_users" rowspan="1" colspan="1" aria-label="User: activate to sort column ascending">
                                User
                            </th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_table_users" rowspan="1" colspan="1" aria-label="Last login: activate to sort column ascending">Location</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_table_users" rowspan="1" colspan="1" aria-label="Joined Date: activate to sort column ascending">Status</th>
                            <th className="min-w-125px w-125px sorting" tabIndex="0" aria-controls="kt_table_users" rowspan="1" colspan="1" aria-label="Role: activate to sort column ascending">Role</th>
                            <th className="text-end min-w-100px sorting_disabled w-130px" rowspan="1" colspan="1" aria-label="Actions">
                                Actions
                            </th>
                        </tr>
                        {/*end::Table row*/}
                    </thead>
                    {/*end::Table head*/}

                    {/*begin::Table body*/}
                    <tbody>
                        <ProfileUserTableRow 
                            name='Melody Macy'
                            email='melody.macy@example.com'
                            location='HQ'
                            role='Admin'
                        />
                        <ProfileUserTableRow 
                            name='Melody Macy'
                            email='melody.macy@example.com'
                            location='HQ'
                            role='Admin'
                        />
                        <ProfileUserTableRow 
                            name='Melody Macy'
                            email='melody.macy@example.com'
                            location='HQ'
                            role='Admin'
                        />
                        <ProfileUserTableRow 
                            name='Melody Macy'
                            email='melody.macy@example.com'
                            location='HQ'
                            role='Admin'
                        />
                        <ProfileUserTableRow 
                            name='Melody Macy'
                            email='melody.macy@example.com'
                            location='HQ'
                            role='Admin'
                        />
                    </tbody>
                    {/*end::Table body*/}
                </table>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                        <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                            <div className="dataTables_paginate paging_simple_numbers" id="kt_table_users_paginate">
                                <ul className="pagination">
                                    <li className="paginate_button page-item previous disabled" id="kt_table_users_previous">
                                        <a href="#" aria-controls="kt_table_users" data-dt-idx="0" tabIndex="0" className="page-link">
                                            <i className="previous"></i>
                                        </a>
                                    </li>
                                    <li className="paginate_button page-item active">
                                        <a href="#" aria-controls="kt_table_users" data-dt-idx="1" tabIndex="0" className="page-link">1</a>
                                    </li>
                                    <li className="paginate_button page-item ">
                                        <a href="#" aria-controls="kt_table_users" data-dt-idx="2" tabIndex="0" className="page-link">2</a>
                                    </li>
                                    <li className="paginate_button page-item ">
                                        <a href="#" aria-controls="kt_table_users" data-dt-idx="3" tabIndex="0" className="page-link">3</a>
                                    </li>
                                    <li className="paginate_button page-item next" id="kt_table_users_next">
                                        <a href="#" aria-controls="kt_table_users" data-dt-idx="4" tabIndex="0" className="page-link"><i className="next"></i></a>
                                    </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileUsers
import React from "react";

function RoleCard({title, rules}) {

    let bullet = []

    rules.forEach(i => {
        bullet.push(<div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3"></span> {i} </div>)
    });

    return (
        <div className="card card-flush h-md-100">
            {/*begin::Card header*/}
            <div className="card-header">
                {/*begin::Card title*/}
                <div className="card-title">
                    <h2>{title}</h2>
                </div>
                {/*end::Card title*/}
            </div>
            {/*end::Card header*/}

            {/*begin::Card body*/}
            <div className="card-body pt-1">
                {/*begin::Users*/}
                {/* <div className="fw-bold text-gray-600 mb-5">Total users with this role: 5</div> */}
                {/*end::Users*/}

                {/*begin::Permissions*/}
                <div className="d-flex flex-column text-gray-600">
                    {bullet}
                </div>
                {/*end::Permissions*/}
            </div>
            {/*end::Card body*/}

            {/*begin::Card footer*/}
            <div className="card-footer flex-wrap pt-0">
                <a href="/keen/demo1/../demo1/apps/user-management/roles/view.html" className="btn btn-light btn-active-primary my-1 me-2">View Role</a>

                <button type="button" className="btn btn-light btn-active-light-primary my-1" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">Edit Role</button>
            </div>
            {/*end::Card footer*/}
        </div>
    )
}

export default RoleCard
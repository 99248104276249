import React from "react";
import ProfielHeaderNavBar from "../../NavBar/ProfileHeaderNavBar";

let business

function getBusiness(id) {
    let name = ''
    switch (id) {
        case 1:
            name = 'WOK - Asian Food'
            break
        case 2:
            name = 'Kobayashi'
            break
        case 3:
            name = 'Linceria Kitchen'
            break
        case 4:
            name = 'Dimsum'
            break
        case 5:
            name = 'Your Brand'
            break
        case 6:
            name = 'Fresh Taste'
            break
        case 7:
            name = 'Pizza Of The Day'
            break
    }
    business = {
        "name": name,
        "logo": "/assets/media/restaurant/" + id + ".jpg"
    }    
}

function ProfileHeaderCard() {
    getBusiness(1)
    return (
        <div className="card mb-9">
            <div className="card-body pt-9 pb-0">
                {/*begin::Details*/}
                <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
                    {/*begin::Image*/}
                    <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4">
                        <img className="w-100 h-100" src={business.logo} alt='logo' />
                    </div>
                    {/*end::Image*/}

                    {/*begin::Wrapper*/}
                    <div className="flex-grow-1">
                        {/*begin::Head*/}
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            {/*begin::Details*/}
                            <div className="d-flex flex-column">
                                {/*begin::Status*/}
                                <div className="d-flex align-items-center mb-1">
                                    <h1 className="fw-bolder me-3 my-6" style={{fontSize: 30}}>{business.name}</h1>
                                    {/* <span className="badge badge-light-success me-auto">Active</span> */}
                                </div>
                                {/*end::Status*/}

                                {/*begin::Description*/}
                                {/* <div className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">
                                    #1 Tool to get started with Web Apps any Kind &amp; size
                                </div> */}
                                {/*end::Description*/}
                            </div>
                            {/*end::Details*/}

                            {/*begin::Actions*/}
                            <div className="d-flex mb-4">
                                <a href="#" className="btn btn-sm btn-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_users_search">Invite User</a>
                            </div>
                            {/*end::Actions*/}
                        </div>
                        {/*end::Head*/}

                        {/*begin::Info*/}
                        <div className="d-flex flex-wrap justify-content-start">
                            {/*begin::Stats*/}
                            <div className="d-flex flex-wrap">
                                {/*begin::Stat*/}
                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    {/*begin::Number*/}
                                    <div className="d-flex align-items-center">
                                        <div className="fs-4 fw-bold">Melody Macy</div>
                                    </div>
                                    {/*end::Number*/}

                                    {/*begin::Label*/}
                                    <div className="fw-semibold fs-6 text-gray-400">Admin</div>
                                    {/*end::Label*/}
                                </div>
                                {/*end::Stat*/}

                                {/*begin::Stat*/}
                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    {/*begin::Number*/}
                                    <div className="d-flex align-items-center">
                                        <div className="fs-4 fw-bold counted" data-kt-countup="true" data-kt-countup-value="75" data-kt-initialized="1">Feb 27, 2023</div>
                                    </div>
                                    {/*end::Number*/}

                                    {/*begin::Label*/}
                                    <div className="fw-semibold fs-6 text-gray-400">Created Account</div>
                                    {/*end::Label*/}
                                </div>
                                {/*end::Stat*/}
                            </div>
                            {/*end::Stats*/}
                        </div>
                        {/*end::Info*/}
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Details*/}

                <div className="separator"></div>
                <ProfielHeaderNavBar />
            </div>
        </div>
    )
}

export default ProfileHeaderCard
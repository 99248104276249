import React, { Component } from 'react'

export class DashboardCustomerCard extends Component {
  render() {
    return (
        <div className="card card-flush h-md-100 mb-xl-10">
            <div className="card-header pt-5">
                <div className="card-title d-flex flex-column">       
                    <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">6.3k</span>
                    <span className="text-gray-400 pt-1 fw-semibold fs-6">New Customers This Month</span>
                </div>        
            </div>
            <div className="card-body d-flex flex-column justify-content-end pe-0">
                <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">Today’s Heroes</span>
                <div className="symbol-group symbol-hover flex-nowrap">
                    <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Alan Warden" data-kt-initialized="1">
                        <span className="symbol-label bg-warning text-inverse-warning fw-bold">A</span>
                    </div>
                    <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" aria-label="Michael Eberon" data-bs-original-title="Michael Eberon" data-kt-initialized="1">
                        <img alt="Pic" src="/assets/media/avatars/300-11.jpg" />
                    </div>
                    <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Susan Redwood" data-kt-initialized="1">
                        <span className="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                    </div>
                    <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" aria-label="Melody Macy" data-bs-original-title="Melody Macy" data-kt-initialized="1">
                        <img alt="Pic" src="/assets/media/avatars/300-2.jpg" />
                    </div>
                    <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Perry Matthew" data-kt-initialized="1">
                        <span className="symbol-label bg-danger text-inverse-danger fw-bold">P</span>
                    </div>
                    <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" aria-label="Barry Walter" data-bs-original-title="Barry Walter" data-kt-initialized="1">
                        <img alt="Pic" src="/assets/media/avatars/300-12.jpg" />
                    </div>
                    <a href="#" className="symbol symbol-35px symbol-circle" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
                        <span className="symbol-label bg-light text-gray-400 fs-8 fw-bold">+42</span>
                    </a>
                </div>
            </div>
        </div>
    )
  }
}

export default DashboardCustomerCard